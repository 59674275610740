<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
                :to="{ path: '/system_Management/system_management_home' }"
                >权限管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>权限列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-button type="primary" @click="addRightDialogVisible = true"
                >添加权限</el-button
            >
            <!-- 一级权限 -->
            <el-row
                v-for="item1 in rightsList"
                :class="[
                    'bottom_bd',
                    item1.nodeType === 'directory' ? 'top_bd' : '',
                    'vcenter',
                ]"
                :key="item1.id"
            >
                <el-col :span="4">
                    <el-button
                        type="primary"
                        plain
                        size="small"
                        @click="clickItem(item1)"
                        >{{ item1.name }}</el-button
                    >
                    <i class="el-icon-caret-right" v-if="item1.childMenu"></i>
                </el-col>
                <!-- 二级权限 -->
                <el-col :span="19">
                    <el-row
                        v-for="item2 in item1.childMenu"
                        :class="[
                            'bottom_bd',
                            item2.nodeType === 'directory' ? 'top_bd' : '',
                            'vcenter',
                        ]"
                        :key="item2.id"
                    >
                        <el-col :span="5">
                            <el-button
                                :type="chooseBtnColor(item2.nodeType)"
                                plain
                                size="small"
                                @click="clickItem(item2)"
                                >{{ item2.name }}</el-button
                            >
                            <i
                                class="el-icon-caret-right"
                                v-if="item2.childMenu"
                            ></i>
                        </el-col>
                        <!-- 三级权限 -->
                        <el-col :span="19">
                            <el-row
                                v-for="item3 in item2.childMenu"
                                :class="[
                                    'bottom_bd',
                                    item3.nodeType === 'directory'
                                        ? 'top_bd'
                                        : '',
                                    'vcenter',
                                ]"
                                :key="item3.id"
                            >
                                <el-col :span="5">
                                    <el-button
                                        :type="chooseBtnColor(item3.nodeType)"
                                        plain
                                        size="small"
                                        @click="clickItem(item3)"
                                        >{{ item3.name }}</el-button
                                    >
                                    <i
                                        class="el-icon-caret-right"
                                        v-if="item3.childMenu"
                                    ></i>
                                </el-col>
                                <el-col :span="19">
                                    <el-button
                                        :type="chooseBtnColor(item4.nodeType)"
                                        plain
                                        size="small"
                                        v-for="item4 in item3.childMenu"
                                        :key="item4.id"
                                        @click="clickItem(item4)"
                                        >{{ item4.name }}</el-button
                                    >
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>
        <!-- 添加权限Dialog -->
        <el-dialog
            title="添加权限"
            :visible.sync="addRightDialogVisible"
            width="50%"
            @close="addRightDialogClose"
        >
            <el-form
                ref="addRightFormRef"
                :model="addRightForm"
                :rules="addRightFormRules"
                label-width="100px"
            >
                <el-form-item label="权限名称：" prop="name">
                    <el-input v-model="addRightForm.name"></el-input>
                </el-form-item>
                <el-form-item label="权限类型：" prop="nodeType">
                    <el-radio-group
                        v-model="addRightForm.nodeType"
                        @change="nodeTypeChange"
                    >
                        <el-radio label="directory">目录权限</el-radio>
                        <el-radio label="function">功能权限</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="权限标识：" prop="code">
                    <el-input v-model="addRightForm.code"></el-input>
                </el-form-item>
                <el-form-item label="权限顺序：" prop="sort">
                    <el-input v-model="addRightForm.sort"></el-input>
                </el-form-item>
                <el-form-item label="父级权限：" prop="parentId">
                    <el-cascader
                        style="width: 100%"
                        :options="rightsList"
                        :props="{
                            checkStrictly: true,
                            label: 'name',
                            value: 'id',
                            children: 'childMenu',
                        }"
                        v-model="selectparentId"
                        @change="selectparentIdChange"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="权限路径：" prop="linkUrl">
                    <el-input v-model="addRightForm.linkUrl"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addRightDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addRight">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 权限详情Dialog -->
        <el-dialog
            title="权限详情"
            :visible.sync="rightDetailDialogVisible"
            width="50%"
        >
            <el-form
                ref="rightDetailFormRef"
                :model="rightDetailForm"
                :rules="rightDetailFormRules"
                label-width="100px"
            >
                <el-form-item label="权限名称：" prop="name">
                    <el-input v-model="rightDetailForm.name"></el-input>
                </el-form-item>
                <el-form-item label="权限类型：" prop="nodeType">
                    <el-radio-group
                        v-model="rightDetailForm.nodeType"
                        @change="nodeTypeChange"
                    >
                        <el-radio label="directory">目录权限</el-radio>
                        <el-radio label="function">功能权限</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="权限标识：" prop="code">
                    <el-input v-model="rightDetailForm.code"></el-input>
                </el-form-item>
                <el-form-item label="权限顺序：" prop="sort">
                    <el-input v-model="rightDetailForm.sort"></el-input>
                </el-form-item>
                <el-form-item label="权限路径：" prop="linkUrl">
                    <el-input v-model="rightDetailForm.linkUrl"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button
                    type="danger"
                    @click="showDeleteRightTip(rightDetailForm.id)"
                    >删 除</el-button
                >
                <el-button @click="rightDetailDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editRight">保 存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getSysMenuTreeData, creatSysMenuTreeData, deleteSysMenuTreeData, editSysMenuTreeData } from '@/http/api'
export default {
  data () {
    return {
      rightsList: [],
      addRightDialogVisible: false,
      addRightForm: {
        name: '',
        parentId: 0,
        level: 0,
        sort: '',
        linkUrl: '',
        nodeType: '',
        code: ''
      },
      selectparentId: [],
      addRightFormRules: {
        name: [{ required: true, message: '请输入权限名称', trigger: 'blur' }],
        nodeType: [{ required: true, message: '请选择权限类型', trigger: 'change' }],
        code: [{ required: true, message: '请输入唯一标识', trigger: 'blur' }]
      },
      rightDetailDialogVisible: false,
      rightDetailForm: {},
      rightDetailFormRules: {
        name: [{ required: true, message: '请输入权限名称', trigger: 'blur' }],
        nodeType: [{ required: true, message: '请选择权限类型', trigger: 'change' }],
        code: [{ required: true, message: '请输入唯一标识', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.getRightsList()
  },
  methods: {
    // 获取树形结构
    getRightsList () {
      getSysMenuTreeData().then((res) => {
        this.rightsList = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 添加权限
    addRight () {
      this.addRightForm.path = this.selectparentId.join(',')
      creatSysMenuTreeData(this.addRightForm).then((res) => {
        this.$message.success('添加权限成功')
        this.addRightDialogVisible = false
        this.getRightsList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 添加弹窗关闭
    addRightDialogClose () {
      this.selectparentId = []
      this.addRightForm.parentId = null
      this.$refs.addRightFormRef.resetFields()
    },
    // 权限类型选中
    nodeTypeChange (value) {
      console.log('value', value)
      if (value === 'directory' && this.addRightDialogVisible === true) {
        this.$set(this.addRightFormRules, 'sort', [{ required: true, message: '请输入排序（数大靠前）', trigger: 'blur' }])
      } else {
        this.$delete(this.addRightFormRules, 'sort')
      }
      if (value === 'directory' && this.rightDetailDialogVisible === true) {
        this.$set(this.rightDetailForm, 'sort', [{ required: true, message: '请输入排序（数大靠前）', trigger: 'blur' }])
      } else {
        this.$delete(this.rightDetailForm, 'sort')
      }
    },
    // 权限选中
    selectparentIdChange () {
      console.log(this.selectparentId)
      if (this.selectparentId.length > 2 && this.addRightForm.type === '0') {
        this.$message.warning('不能创建四级目录')
        this.selectparentId = ''
      } else {
        this.addRightForm.parentId = this.selectparentId[this.selectparentId.length - 1]
        this.addRightForm.level = this.selectparentId.length
      }
    },
    // 显示编辑弹窗
    clickItem (itemData) {
      this.rightDetailForm = itemData
      this.rightDetailDialogVisible = true
    },
    // 编辑保存
    editRight () {
      editSysMenuTreeData(this.rightDetailForm).then((res) => {
        this.$message.success('保存权限成功')
        this.rightDetailDialogVisible = false
        this.selectparentId = []
        this.$refs.rightDetailFormRef.resetFields()
        this.getRightsList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 删除权限
    showDeleteRightTip (id) {
      this.$confirm('此操作将永久删除该权限及可能存在的子权限, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteRightById(id)
      }).catch(() => { })
    },
    deleteRightById (id) {
      deleteSysMenuTreeData({ id: id }).then((res) => {
        this.$message.success('删除权限成功')
        this.rightDetailDialogVisible = false
        this.getRightsList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    chooseBtnColor (type) {
      if (type === 'directory') {
        return 'primary'
      } else {
        return 'success'
      }
    }
  }
}
</script>

  <style lang="less" scoped>
.el-button {
    margin: 7px;
}

.top_bd {
    border-top: 1px solid #eee;
}

.bottom_bd {
    border-bottom: 1px solid #eee;
}

.vcenter {
    display: flex;
    align-items: center;
}
</style>
